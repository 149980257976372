import {
  EyeInvisibleOutlined,
  EyeTwoTone,
  LockOutlined,
  MailOutlined,
} from "@ant-design/icons";
import { Button, Col, Form, Input, Layout, Row, Typography } from "antd";
import { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import logo from "src/assets/images/dark-logo.png";
import AppReleaseVersion from "src/components/AppReleaseVersion";
import CustomForm from "src/components/CustomForm";
import { ASCII_REGEX, ROLES } from "src/constants";
import TextConstants from "src/constants/TextConstants";
import { PATHS } from "src/routes";
import { LOGOUT, getAppReleaseVersion, login } from "src/store/actions";
const { Content } = Layout;

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const { loading, user, type: authType } = useSelector((state) => state.Auth);

  useEffect(() => {
    console.log("authType", authType);
    if (authType === LOGOUT.SUCCESS) {
      dispatch(getAppReleaseVersion());
    }
  }, [dispatch, authType]);

  const onFinish = useCallback(
    (params) => {
      const queryStringObj = new URLSearchParams(location.search);
      const token = queryStringObj.get("token");
      dispatch(login({ ...params, token }));
    },
    [dispatch, location]
  );

  const isAuthenticated = useMemo(() => !!user, [user]);

  if (isAuthenticated && user) {
    if (user.role === ROLES.SA) {
      return <Navigate to={PATHS.ADMIN_USERS} />;
    }

    if ([ROLES.FA, ROLES.SU].includes(user.role)) {
      return <Navigate to={PATHS.DASHBOARD} />;
    }
  }

  const redirectToCreateAccountPage = () => {
    navigate(`${PATHS.SIGNUP}`);
  };

  const redirectToPasswordResetRequestPage = () => {
    navigate(`${PATHS.PASSWORD_RESET_REQUEST}`);
  };

  return (
    <Layout className="layout-default layout-signin">
      <Content className="signin">
        <Row gutter={[24, 0]} justify="space-around">
          <Col
            xxl={{ span: 5 }}
            xl={{ span: 8 }}
            lg={{ span: 12 }}
            md={{ span: 16 }}
            xs={{ span: 24 }}
          >
            <div className="d-flex justify-content-center">
              <img src={logo} alt="LICONEX LITE" className="home-logo" />
            </div>
            <CustomForm
              onFinish={onFinish}
              layout="vertical"
              className="row-col"
            >
              <Form.Item
                className="email"
                label={
                  <Typography.Text strong>
                    {TextConstants.Common.EmailAddress}
                  </Typography.Text>
                }
                name="email"
                rules={[
                  {
                    required: true,
                    type: "email",
                  },
                  {
                    pattern: ASCII_REGEX,
                    message: TextConstants.Common.OnlyAcceptASCIIMessage,
                  },
                  {
                    max: 254,
                    message:
                      TextConstants.Common.MaxLengthWarningMessage.replace(
                        "{max}",
                        "254"
                      ),
                  },
                ]}
              >
                <Input
                  autoFocus
                  addonBefore={<MailOutlined style={{ color: "#fff" }} />}
                  placeholder={TextConstants.Common.EmailAddress}
                />
              </Form.Item>
              <Form.Item
                className="password"
                label={
                  <Typography.Text strong>
                    {TextConstants.Common.Password}
                  </Typography.Text>
                }
                name="password"
                rules={[
                  {
                    required: true,
                  },
                  {
                    max: 99,
                    message:
                      TextConstants.Common.MaxLengthWarningMessage.replace(
                        "{max}",
                        99
                      ),
                  },
                ]}
              >
                <Input.Password
                  addonBefore={<LockOutlined style={{ color: "#fff" }} />}
                  placeholder={TextConstants.Common.Password}
                  iconRender={(visible) =>
                    visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                  }
                />
              </Form.Item>
              <Form.Item>
                <Button
                  loading={loading}
                  type="primary"
                  htmlType="submit"
                  style={{ width: "100%" }}
                >
                  {TextConstants.Common.Login}
                </Button>
              </Form.Item>
              <Form.Item>
                <Typography.Link
                  onClick={redirectToCreateAccountPage}
                  style={{ marginRight: "10px" }}
                >
                  {TextConstants.Common.CreateAccount}
                </Typography.Link>
                <Typography.Link
                  onClick={redirectToPasswordResetRequestPage}
                  style={{ float: "right" }}
                >
                  {TextConstants.Common.ForgotPassword}
                </Typography.Link>
              </Form.Item>
              <Form.Item>
                <hr className="custom-hr" />
                <AppReleaseVersion />
              </Form.Item>
            </CustomForm>
          </Col>
        </Row>
      </Content>
    </Layout>
  );
};

export default Login;
