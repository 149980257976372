import * as Types from "src/store/actions";

export const updateNewDeploymentLocal = (params, callback = null) => ({
  type: Types.UPDATE_NEW_DEPLOYMENT_LOCAL,
  payload: params,
  callback,
});

export const getFloors = (params, callback = null) => ({
  type: Types.GET_FLOORS.REQUEST,
  payload: params,
  callback,
});

export const getFacilityFloor = (params, callback = null) => ({
  type: Types.GET_FACILITY_FLOOR.REQUEST,
  payload: params,
  callback,
});

export const createFloor = (params, callback = null) => ({
  type: Types.CREATE_FLOOR.REQUEST,
  payload: params,
  callback,
});

export const updateFloor = (params, callback = null) => ({
  type: Types.UPDATE_FLOOR.REQUEST,
  payload: params,
  callback,
});

export const deleteFloor = (params, callback = null) => ({
  type: Types.DELETE_FLOOR.REQUEST,
  payload: params,
  callback,
});

export const getFloorMapSettingFileURL = (params, callback = null) => ({
  type: Types.GET_FLOOR_MAP_SETTINGS_FILE_URL.REQUEST,
  payload: params,
  callback,
});
