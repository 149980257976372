import { all } from "redux-saga/effects";
import backendSagas from "./backend";

import authSagas from "./auth";
import companiesSagas from "./companies";
import officesSagas from "./offices";
import usersSagas from "./users";
import measurementsSagas from "./measurements";

import floorsSagas from "./floors";
import lightsSagas from "./lights";

import facilitiesSagas from "./facilities";

export default function* rootSaga(getState) {
  yield all([
    backendSagas(),

    authSagas(),
    usersSagas(),
    companiesSagas(),
    officesSagas(),
    measurementsSagas(),

    floorsSagas(),
    lightsSagas(),

    facilitiesSagas(),
  ]);
}
