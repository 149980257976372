import { createActionTypes } from "../utils";

export const GET_APP_RELEASE_VERSION = createActionTypes("GET_APP_RELEASE_VERSION");

export const LOGIN = createActionTypes("LOGIN");
export const LOGOUT = createActionTypes("LOGOUT");
export const SIGNUP = createActionTypes("SIGNUP");
export const PASSWORD_RESET_REQUEST = createActionTypes(
  "PASSWORD_RESET_REQUEST"
);
export const RESET_PASSWORD = createActionTypes("RESET_PASSWORD");
export const CONFIRM_EMAIL_ADDRESS = createActionTypes("CONFIRM_EMAIL_ADDRESS");

export const GET_USERS = createActionTypes("GET_USERS");
export const CREATE_USER = createActionTypes("CREATE_USER");
export const CHANGE_USER_PASSWORD = createActionTypes("CHANGE_USER_PASSWORD");
export const DELETE_USER = createActionTypes("DELETE_USER");
export const UPDATE_USER_INFO = createActionTypes("UPDATE_USER_INFO");

export const INVITE_FACILITY_USERS = createActionTypes("INVITE_FACILITY_USERS");
export const GET_FACILITY_USERS = createActionTypes("GET_FACILITY_USERS");
export const DELETE_FACILITY_USER = createActionTypes("DELETE_FACILITY_USER");

export const GET_COMPANIES = createActionTypes("GET_COMPANIES");
export const CREATE_COMPANY = createActionTypes("CREATE_COMPANY");
export const UPDATE_COMPANY = createActionTypes("UPDATE_COMPANY");
export const DELETE_COMPANY = createActionTypes("DELETE_COMPANY");

export const GET_OFFICES = createActionTypes("GET_OFFICES");
export const CREATE_OFFICE = createActionTypes("CREATE_OFFICE");
export const UPDATE_OFFICE = createActionTypes("UPDATE_OFFICE");
export const DELETE_OFFICE = createActionTypes("DELETE_OFFICE");

export const GET_LIGHTS = createActionTypes("GET_LIGHTS");
export const CREATE_LIGHT = createActionTypes("CREATE_LIGHT");
export const UPDATE_LIGHT = createActionTypes("UPDATE_LIGHT");
export const DELETE_LIGHT = createActionTypes("DELETE_LIGHT");

export const GET_MEASUREMENTS = createActionTypes("GET_MEASUREMENTS");

export const UPDATE_NEW_DEPLOYMENT_LOCAL = "UPDATE_NEW_DEPLOYMENT_LOCAL";
export const GET_FLOORS = createActionTypes("GET_FLOORS");
export const CREATE_FLOOR = createActionTypes("CREATE_FLOOR");
export const UPDATE_FLOOR = createActionTypes("UPDATE_FLOOR");
export const DELETE_FLOOR = createActionTypes("DELETE_FLOOR");
export const GET_FACILITY_FLOOR = createActionTypes("GET_FACILITY_FLOOR");
export const GET_FLOOR_MAP_SETTINGS_FILE_URL = createActionTypes(
  "GET_FLOOR_MAP_SETTINGS_FILE_URL"
);

export const GET_FACILITIES = createActionTypes("GET_FACILITIES");
export const CREATE_FACILITY = createActionTypes("CREATE_FACILITY");
export const UPDATE_FACILITY = createActionTypes("UPDATE_FACILITY");
export const DELETE_FACILITY = createActionTypes("DELETE_FACILITY");

export const ACCEPT_FACILITY_INVITATION = createActionTypes("ACCEPT_FACILITY_INVITATION");

// ---------------------------------------------------
export * from "./backend";

export * from "./auth";
export * from "./companies";
export * from "./offices";
export * from "./users";
export * from "./measurements";

export * from "./floors";
export * from "./lights";

export * from "./facilities";
