import { combineReducers } from "redux";
import * as Actions from "../actions";

import Backend from "./backend";

import Auth from "./auth";
import Companies from "./companies";
import Measurements from "./measurements";
import Offices from "./offices";
import Users from "./users";

import Floors from "./floors";
import Lights from "./lights";

import Facilities from "./facilities";

/*
 * Combines all the existing reducers from /common/store/... and /views/...
 */
const reducers = combineReducers({
  Backend,

  Auth,
  Users,
  Companies,
  Offices,
  Measurements,

  Floors,
  Lights,

  Facilities,
});

const rootReducer = (state, action) => {
  // Clear all data in redux store to initial.
  if ([Actions.LOGOUT.SUCCESS].includes(action.type)) {
    state = undefined;
  }
  return reducers(state, action);
};

export default rootReducer;
