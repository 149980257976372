import { KeyOutlined, LoadingOutlined, MailOutlined } from "@ant-design/icons";
import { Button, Menu, Row, Tooltip } from "antd";
import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useLocation } from "react-router-dom";

import logoWhite from "src/assets/images/logo-full-white.png";
import AppReleaseVersion from "src/components/AppReleaseVersion";
import { ROLES } from "src/constants";
import TextConstants from "src/constants/TextConstants";
import items from "src/containers/_nav";
import { PATHS } from "src/routes";
import { getFacilities } from "src/store/actions";

const Sidenav = () => {
  let { pathname } = useLocation();
  const dispatch = useDispatch();

  const { facilities, loading: isFetchingFacilities } = useSelector(
    (state) => state.Facilities
  );
  const { user } = useSelector((state) => state.Auth);
  const role = useMemo(() => user.role, [user]);

  useEffect(() => {
    if ([ROLES.FA, ROLES.SU].includes(role)) {
      dispatch(getFacilities());
    }
  }, [role]);

  const menuItems = useMemo(() => {
    return items
      .filter((item) => item.roles.includes(role))
      .map((item) => {
        if (item.route === PATHS.DASHBOARD && [ROLES.FA].includes(role)) {
          return {
            ...item,
            key: "sub-menu-always-open",
            className: "ant-menu-sub-custom",
            label:
              item.route === PATHS.DASHBOARD ? (
                item.label
              ) : (
                <NavLink to={item.route}>{item.label}</NavLink>
              ),
            children:
              isFetchingFacilities && facilities && facilities.length === 0
                ? [
                    {
                      key: "_loading",
                      type: "group",
                      label: (
                        <a
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <span className="label">
                            <LoadingOutlined style={{ marginRight: 8 }} />
                            {TextConstants.Common.Loading}
                          </span>
                        </a>
                      ),
                    },
                  ]
                : [...facilities]
                    .sort((a, b) =>
                      a.facilityName.localeCompare(b.facilityName)
                    )
                    .map((facility) => {
                      return {
                        key: facility.facilityId,
                        type: "group",
                        label: (
                          <NavLink
                            to={PATHS.FACILITY_DETAIL.replace(
                              ":facilityId",
                              facility.facilityId
                            )}
                          >
                            {facility.isOwner ? (
                              <Tooltip title={TextConstants.Common.Owner}>
                                <KeyOutlined style={{ marginRight: 4 }} />
                              </Tooltip>
                            ) : (
                              <Tooltip title={TextConstants.Common.IsInvited}>
                                <MailOutlined style={{ marginRight: 4 }} />
                              </Tooltip>
                            )}
                            {facility.facilityName}
                          </NavLink>
                        ),
                      };
                    }),
          };
        }
        return {
          ...item,
          key: item.route,
          label: <NavLink to={item.route}>{item.label}</NavLink>,
        };
      });
  }, [items, role, facilities, isFetchingFacilities]);

  return (
    <>
      <Row justify={"center"} gutter={[24, 10]} className="sider-header">
        <img
          src={logoWhite}
          alt={TextConstants.PortalName}
          style={{ height: 50 }}
        />
        {pathname && pathname.indexOf("/admin/") !== -1 && (
          <Button size="small" className="goto-admin-screen">
            {TextConstants.Common.SystemScreenAdmin}
          </Button>
        )}
      </Row>
      <Menu
        items={menuItems}
        mode="inline"
        subMenuCloseDelay={0}
        className="custom-left-sidebar-menu"
        defaultOpenKeys={["sub-menu-always-open"]}
        selectedKeys={pathname}
      />
      <div className="left-sidebar-app-version-container">
        <AppReleaseVersion />
      </div>
    </>
  );
};

export default Sidenav;
