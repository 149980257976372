import { Col, Form, Input, InputNumber, Row, Typography } from "antd";
import React, { useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import CustomForm from "src/components/CustomForm";
import TextConstants from "src/constants/TextConstants";
import { Notification } from "src/helpers/Notification";
import { PATHS } from "src/routes";
import { updateFloor } from "src/store/actions";
import StepControlButtons from "./components/StepControlButtons/StepControlButtons";
import MaxLightSensorNumberWarningTooltip from "../components/MaxLightSensorNumberWarningTooltip";

const InitFloorInfo = ({ facilityId, floorId, facilityFloor, isUpdating }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const moveToFacilityDetail = () => {
    navigate(PATHS.FACILITY_DETAIL.replace(":facilityId", facilityId));
  };

  const onFloorUpdatedSuccess = () => {
    moveToFacilityDetail();
    Notification.success(TextConstants.UpdateFloorModal.SuccessMessage);
  };

  const onFinish = useCallback(
    (formValues) => {
      const floorDataUpdated = {
        facilityId: facilityFloor.facilityId,
        floorId: facilityFloor.floorId,
        name: formValues.name,
        numberLights: formValues.numberLights,
        dimensions: [formValues.vertical, formValues.length, formValues.height],
      };

      dispatch(updateFloor(floorDataUpdated, onFloorUpdatedSuccess));
    },
    [dispatch, onFloorUpdatedSuccess, facilityFloor]
  );

  const formInitValues = {
    name: "",
    vertical: "",
    length: "",
    height: "",
  };

  useEffect(() => {
    if (facilityFloor && facilityFloor.floorId === floorId) {
      form.setFieldsValue({
        name: facilityFloor.name || "",
        vertical: facilityFloor.dimensions?.[0],
        length: facilityFloor.dimensions?.[1],
        height: facilityFloor.dimensions?.[2],
        numberLights: facilityFloor.numberLights,
      });
    }
  }, [form, facilityFloor, floorId]);

  const onGoNext = useCallback(() => {
    form.submit();
  }, [form]);

  return (
    <React.Fragment>
      <CustomForm
        key={floorId}
        form={form}
        initialValues={formInitValues}
        name="basic"
        onFinish={onFinish}
        autoComplete="off"
      >
        <Row gutter={[24, 0]}>
          <Col lg={{ span: 4 }} md={{ span: 8 }} xs={{ span: 24 }}>
            <label htmlFor="floorName">
              <Typography.Text type="danger">*</Typography.Text>
              {TextConstants.FacilityFloors.FloorName}：
            </label>
          </Col>
          <Col lg={{ span: 20 }} md={{ span: 16 }} xs={{ span: 24 }}>
            <Form.Item
              name="name"
              rules={[
                {
                  required: true,
                },
                {
                  validator: (_, value) =>
                    value.length > 0 && !value.trim()
                      ? Promise.reject(
                          new Error(TextConstants.Common.ThisFieldIsRequired)
                        )
                      : Promise.resolve(),
                },
                {
                  max: 255,
                  message: TextConstants.Common.MaxLengthWarningMessage.replace(
                    "{max}",
                    "255"
                  ),
                },
              ]}
            >
              <Input
                autoFocus
                id="floorName"
                placeholder={TextConstants.FacilityFloors.FloorNameExample}
                style={{ maxWidth: 350 }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[24, 0]}>
          <Col lg={{ span: 4 }} md={{ span: 8 }} xs={{ span: 24 }}>
            <label>
              <Typography.Text type="danger">*</Typography.Text>
              {TextConstants.FacilityFloors.FloorSize}：
            </label>
          </Col>
          <Col lg={{ span: 20 }} md={{ span: 16 }} xs={{ span: 24 }}>
            <Row>
              <Col xs={{ span: 24 }}>
                <Form.Item
                  label={TextConstants.FacilityFloors.Vertical}
                  htmlFor="floorWidth"
                >
                  <Form.Item
                    noStyle
                    name="vertical"
                    rules={[
                      {
                        required: true,
                      },
                      {
                        type: "number",
                        min: 1,
                        max: 500,
                        message:
                          TextConstants.Common.MaxMinNumberValidateMessage.replace(
                            "{min}",
                            1
                          ).replace("{max}", 500),
                      },
                    ]}
                  >
                    <InputNumber id="floorWidth" />
                  </Form.Item>
                  <span style={{ marginLeft: "10px" }}>m</span>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col xs={{ span: 24 }}>
                <Form.Item
                  label={TextConstants.FacilityFloors.Length}
                  htmlFor="floorLength"
                >
                  <Form.Item
                    noStyle
                    name="length"
                    rules={[
                      {
                        required: true,
                      },
                      {
                        type: "number",
                        min: 1,
                        max: 500,
                        message:
                          TextConstants.Common.MaxMinNumberValidateMessage.replace(
                            "{min}",
                            1
                          ).replace("{max}", 500),
                      },
                    ]}
                  >
                    <InputNumber id="floorLength" />
                  </Form.Item>
                  <span style={{ marginLeft: "10px" }}>m</span>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col xs={{ span: 24 }}>
                <Form.Item
                  label={TextConstants.FacilityFloors.Height}
                  htmlFor="floorHeight"
                >
                  <Form.Item
                    name="height"
                    noStyle
                    rules={[
                      {
                        required: true,
                      },
                      {
                        type: "number",
                        min: 1,
                        message:
                          TextConstants.Common.InvalidMinNumberMessage.replace(
                            "{num}",
                            1
                          ),
                      },

                      {
                        type: "number",
                        max: 15,
                        message:
                          TextConstants.NewFloorMapSettings
                            .ExceededMaxHeightErrorMessage,
                      },
                    ]}
                  >
                    <InputNumber id="floorHeight" />
                  </Form.Item>
                  <span style={{ marginLeft: "10px" }}>m</span>
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row gutter={[24, 0]}>
          <Col lg={{ span: 4 }} md={{ span: 8 }} xs={{ span: 24 }}>
            <label htmlFor="numberLights">
              <Typography.Text type="danger">*</Typography.Text>
              {TextConstants.FacilityFloors.TotalLightsLabel}：
            </label>
          </Col>
          <Col lg={{ span: 20 }} md={{ span: 16 }} xs={{ span: 24 }}>
            <Form.Item
              name="numberLights"
              rules={[
                {
                  required: true,
                },
                {
                  type: "number",
                  min: 1,
                  max: 100,
                  message: (
                    <>
                      {TextConstants.Common.MaxMinNumberValidateMessage.replace(
                        "{min}",
                        "1"
                      ).replace("{max}", "100")}
                      {<MaxLightSensorNumberWarningTooltip />}
                    </>
                  ),
                },
                {
                  validator: (_, value) => {
                    if (!value || Number.isInteger(Number(value))) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(
                        TextConstants.Common.MaxMinNumberValidateMessage.replace(
                          "{min}",
                          "1"
                        ).replace("{max}", "200")
                      )
                    );
                  },
                },
              ]}
            >
              <InputNumber id="numberLights" />
            </Form.Item>
          </Col>
        </Row>
      </CustomForm>
      <StepControlButtons
        backLabel={TextConstants.Common.Cancel}
        onGoBack={moveToFacilityDetail}
        isShowBackIcon={false}
        onGoNext={onGoNext}
        isShowNextIcon={false}
        nextLabel={TextConstants.Common.Submit}
        onGoNextLoading={isUpdating}
      />
    </React.Fragment>
  );
};

export default InitFloorInfo;
