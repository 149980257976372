import {
  LoadingOutlined,
  LogoutOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  SettingFilled,
  UserOutlined,
} from "@ant-design/icons";
import {
  Col,
  Drawer,
  Dropdown,
  Form,
  Input,
  Layout,
  Modal,
  PageHeader,
  Row,
  Space,
  Typography,
} from "antd";
import React, { useCallback, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";
import CustomForm from "src/components/CustomForm";
import { ROLES } from "src/constants";
import TextConstants from "src/constants/TextConstants";
import { PATHS } from "src/routes";
import { logout, updateUserInfo } from "src/store/actions";
import Sidenav from "./Sidenav";

const siderWidth = 300;
const { Content, Sider } = Layout;

const LogoutConfirmModal = ({
  isModalOpen = false,
  setIsOpenModal = () => {},
}) => {
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.Auth);

  const handleOk = useCallback(() => {
    dispatch(logout());
  }, [dispatch]);

  return (
    <Modal
      confirmLoading={loading}
      title={
        <>
          <strong>
            {TextConstants.Common.Logout}
            <LogoutOutlined style={{ marginLeft: 10 }} />
          </strong>
        </>
      }
      visible={isModalOpen}
      onOk={handleOk}
      onCancel={() => {
        setIsOpenModal(false);
      }}
      okText={TextConstants.Common.OK}
      cancelText={TextConstants.Common.Cancel}
    >
      <p>{TextConstants.Common.ConfirmLogout}</p>
    </Modal>
  );
};

const EditUserInfoModal = ({
  isModalOpen = false,
  setIsOpenModal = () => {},
}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { user } = useSelector((state) => state.Auth);
  const { loading } = useSelector((state) => state.Users);

  const handleOk = useCallback(() => {
    form.submit();
  }, [form]);

  const onUpdateUserInfo = useCallback(
    (formValues) => {
      dispatch(
        updateUserInfo(formValues, () => {
          setIsOpenModal(false);
        })
      );
    },
    [dispatch]
  );

  const initValues = {
    companyName: user.companyName,
    departmentName: user.departmentName,
  };

  return (
    <Modal
      confirmLoading={loading}
      title={
        <>
          <strong>
            {TextConstants.Common.UpdateUserInfo}
            <SettingFilled style={{ marginLeft: 10 }} />
          </strong>
        </>
      }
      visible={isModalOpen}
      onOk={handleOk}
      onCancel={() => {
        setIsOpenModal(false);
      }}
      okText={TextConstants.Common.Submit}
      cancelText={TextConstants.Common.Cancel}
    >
      <CustomForm
        form={form}
        onFinish={onUpdateUserInfo}
        layout="vertical"
        className="row-col"
        initialValues={initValues}
      >
        <Row gutter={[24, 0]}>
          <Col xs={24} md={24}>
            <Form.Item
              label={TextConstants.Common.Company}
              name="companyName"
              rules={[
                {
                  required: true,
                },
                {
                  max: 255,
                  message: TextConstants.Common.MaxLengthWarningMessage.replace(
                    "{max}",
                    "255"
                  ),
                },
              ]}
            >
              <Input placeholder={TextConstants.Common.CompanyName} />
            </Form.Item>
          </Col>
          <Col xs={24} md={24}>
            <Form.Item
              label={TextConstants.Common.GroupDepartmentName}
              name="departmentName"
              rules={[
                {
                  max: 255,
                  message: TextConstants.Common.MaxLengthWarningMessage.replace(
                    "{max}",
                    "255"
                  ),
                },
              ]}
            >
              <Input placeholder={TextConstants.Common.GroupDepartmentName} />
            </Form.Item>
          </Col>
        </Row>
      </CustomForm>
    </Modal>
  );
};

const LoggedInUserInfo = ({ user }) => {
  const [isOpenLogoutModal, setIsOpenLogoutModal] = useState(false);
  const [isOpenEditUserInfoModal, setIsOpenEditUserInfoModal] = useState(false);

  const username = useMemo(() => {
    return (
      <>
        {(user &&
          (user.role === ROLES.SA
            ? TextConstants.ROLES.SYSTEM_ADMIN
            : user.name)) || <LoadingOutlined />}
      </>
    );
  }, [user]);

  const userInfoMenuItems = useMemo(() => {
    if (!user) {
      return [];
    }

    return [
      ...(user.role !== ROLES.SA
        ? [
            {
              label: `${TextConstants.Common.Company}：${user.companyName}`,
              key: "_company-name",
            },
          ]
        : []),
      {
        label: `${TextConstants.Common.Role}：${
          TextConstants.ROLES[user.role]
        }`,
        key: "_user-role",
      },
      ...(user.role !== ROLES.SA
        ? [
            {
              label: `${TextConstants.Common.GroupDepartmentName}：${
                user.departmentName || TextConstants.Common.None
              }`,
              key: "_user_department",
            },
          ]
        : []),
      {
        label: `${TextConstants.Common.EmailAddress}：${user.email}`,
        key: "_user-email",
      },
      ...(user.role !== ROLES.SA
        ? [
            {
              label: (
                <Typography.Link
                  onClick={() => {
                    setIsOpenEditUserInfoModal(true);
                  }}
                >
                  {TextConstants.Common.UpdateUserInfo} <SettingFilled />
                </Typography.Link>
              ),
              className: "user-info-dropdown-update-user-info-item",
              key: "_update_user_info",
            },
          ]
        : []),
      {
        label: (
          <>
            <a
              onClick={() => {
                setIsOpenLogoutModal(true);
              }}
            >
              <span className="label">
                <strong>{TextConstants.Common.Logout}</strong>
              </span>
              <span className="icon" style={{ marginLeft: 10 }}>
                {false ? <LoadingOutlined /> : <LogoutOutlined />}
              </span>
            </a>
          </>
        ),
        className: "user-info-dropdown-logout-item",
        key: "_logout",
      },
    ];
  }, [user]);

  return (
    <React.Fragment>
      <Dropdown menu={{ items: userInfoMenuItems }}>
        <a>
          <Space>
            {username}
            <UserOutlined />
          </Space>
        </a>
      </Dropdown>
      <LogoutConfirmModal
        isModalOpen={isOpenLogoutModal}
        setIsOpenModal={setIsOpenLogoutModal}
      />
      <EditUserInfoModal
        isModalOpen={isOpenEditUserInfoModal}
        setIsOpenModal={setIsOpenEditUserInfoModal}
      />
    </React.Fragment>
  );
};

const Main = ({ children }) => {
  const { user } = useSelector((state) => state.Auth);

  const [visible, setVisible] = useState(false);

  let { pathname } = useLocation();

  const isAuthenticated = useMemo(() => !!user, [user]);

  if (!isAuthenticated) {
    return <Navigate to={PATHS.LOGIN} />;
  }

  return (
    <Layout className="layout-dashboard">
      {!pathname.includes(PATHS.NEW_DEPLOYMENT) && (
        <Sider
          breakpoint="lg"
          collapsedWidth="0"
          trigger={null}
          width={siderWidth}
          className={`sider-primary ant-layout-sider-primary`}
        >
          <Sidenav />
        </Sider>
      )}
      <Drawer
        title={false}
        placement="left"
        closable={false}
        onClose={() => setVisible(false)}
        open={visible}
        key="left"
        width={siderWidth}
        className="drawer-sidebar"
      >
        <Layout className="layout-dashboard">
          <Sider
            trigger={null}
            width={siderWidth}
            theme="light"
            className={`sider-primary ant-layout-sider-primary`}
            style={{ background: "transparent" }}
          >
            <Sidenav />
          </Sider>
        </Layout>
      </Drawer>
      <Layout
        style={pathname.includes(PATHS.NEW_DEPLOYMENT) ? { marginLeft: 0 } : {}}
      >
        <PageHeader
          className="page-header-custom"
          ghost={false}
          title={
            visible ? (
              <MenuUnfoldOutlined />
            ) : (
              <MenuFoldOutlined
                onClick={() => {
                  setVisible(true);
                }}
              />
            )
          }
          extra={[<LoggedInUserInfo user={user} />]}
        />
        <Content className="content-ant page-content-custom">
          {children}
        </Content>
      </Layout>
    </Layout>
  );
};

export default Main;
