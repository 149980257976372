import {
  EyeInvisibleOutlined,
  EyeTwoTone,
  LockOutlined,
} from "@ant-design/icons";
import { Button, Col, Form, Input, Layout, Row, Typography } from "antd";
import dayjs from "dayjs";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import logo from "src/assets/images/dark-logo.png";
import AppReleaseVersion from "src/components/AppReleaseVersion";
import CustomForm from "src/components/CustomForm";
import InvalidPasswordMessage from "src/components/InvalidPasswordMessage";
import { JWT_REGEX, PASSWORD_REGEX, ROLES } from "src/constants";
import TextConstants from "src/constants/TextConstants";
import { parseJwt } from "src/helpers/AuthHelper";
import { Notification } from "src/helpers/Notification";
import useQuery from "src/helpers/hooks/useQuery";
import { PATHS } from "src/routes";
import { resetPassword } from "src/store/actions";
const { Content } = Layout;

const ResetPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const query = useQuery();

  const { loading, user } = useSelector((state) => state.Auth);
  const [token, setToken] = useState("");

  const redirectToLoginPage = () => {
    navigate(`${PATHS.LOGIN}`);
  };

  useEffect(() => {
    // get the user's token
    const userToken = query.get("token");
    if (!userToken || !JWT_REGEX.test(userToken)) redirectToLoginPage();
    // parse the token payload and get the username
    const parseToken = parseJwt(userToken);

    if (parseToken.exp) {
      const expTime = (parseToken.exp || 0) * 1000;
      if (dayjs().isAfter(dayjs(expTime))) {
        Notification.error(TextConstants.Common.ExpiredLink);
        redirectToLoginPage();
      }
    }

    setToken(userToken);
  }, [query, redirectToLoginPage, setToken]);

  const passwordResetSuccessCallback = () => {
    setTimeout(() => {
      redirectToLoginPage();
    }, 2000);
    Notification.success(TextConstants.Common.Success);
  };

  const onFinish = useCallback(
    (params) => {
      const payload = { ...params, token };
      dispatch(resetPassword(payload, passwordResetSuccessCallback));
    },
    [resetPassword, dispatch, token]
  );

  const isAuthenticated = useMemo(() => !!user, [user]);

  if (isAuthenticated && user) {
    if (user.role === ROLES.SA) {
      return <Navigate to={PATHS.ADMIN_USERS} />;
    }

    if ([ROLES.FA, ROLES.SU].includes(user.role)) {
      return <Navigate to={PATHS.DASHBOARD} />;
    }
  }

  return (
    <Layout className="layout-default layout-signin">
      <Content className="signin">
        <Row gutter={[24, 0]} justify="space-around">
          <Col
            xxl={{ span: 5 }}
            xl={{ span: 8 }}
            lg={{ span: 12 }}
            md={{ span: 16 }}
            xs={{ span: 24 }}
          >
            <div className="d-flex justify-content-center">
              <img src={logo} alt="LICONEX LITE" className="home-logo" />
            </div>
            <div className="d-flex justify-content-center mb-20">
              <Typography.Title level={3}>
                {TextConstants.Common.ResetPassword}
              </Typography.Title>
            </div>
            <CustomForm
              onFinish={onFinish}
              layout="vertical"
              className="row-col"
            >
              <Form.Item
                className="password"
                label={
                  <Typography.Text strong>
                    {TextConstants.Common.Password}
                  </Typography.Text>
                }
                name="password"
                rules={[
                  {
                    required: true,
                  },
                  {
                    pattern: PASSWORD_REGEX,
                    message: <InvalidPasswordMessage />,
                  },
                ]}
              >
                <Input.Password
                  addonBefore={<LockOutlined style={{ color: "#fff" }} />}
                  placeholder={TextConstants.Common.Password}
                  iconRender={(visible) =>
                    visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                  }
                />
              </Form.Item>
              <Form.Item
                className="password"
                label={
                  <Typography.Text strong>
                    {TextConstants.Common.ConfirmPassword}
                  </Typography.Text>
                }
                name="confirmPassword"
                dependencies={["password"]}
                rules={[
                  {
                    required: true,
                    message: TextConstants.Common.EnterConfirmPassword,
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error(TextConstants.Common.NotMatchPassword)
                      );
                    },
                  }),
                ]}
              >
                <Input.Password
                  addonBefore={<LockOutlined style={{ color: "#fff" }} />}
                  placeholder={TextConstants.Common.ConfirmPassword}
                  iconRender={(visible) =>
                    visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                  }
                />
              </Form.Item>
              <Form.Item>
                <Button
                  loading={loading}
                  type="primary"
                  htmlType="submit"
                  style={{ width: "130px", textTransform: "uppercase" }}
                >
                  {TextConstants.Common.Reset}
                </Button>
                <Button
                  type="link"
                  htmlType="button"
                  onClick={redirectToLoginPage}
                  style={{ float: "right" }}
                >
                  {TextConstants.Common.Login}
                </Button>
              </Form.Item>
              <Form.Item>
                <hr className="custom-hr" />
                <AppReleaseVersion />
              </Form.Item>
            </CustomForm>
          </Col>
        </Row>
      </Content>
    </Layout>
  );
};

export default ResetPassword;
