import {
  Button,
  Card,
  Col,
  Divider,
  Popconfirm,
  Row,
  Select,
  Space,
  Typography,
} from "antd";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import BorderTable from "src/components/BorderTable";
import TextConstants from "src/constants/TextConstants";
import {
  changeUserPassword,
  createUser,
  deleteUser,
  getCompanies,
  getUsers,
} from "src/store/actions";
import ChangePasswordModal from "./ChangePasswordModal";
import CreateUserModal from "./CreateUserModal";

const Users = () => {
  const dispatch = useDispatch();

  const { user: loggedInUser } = useSelector((state) => state.Auth);

  const { loading, users } = useSelector((state) => state.Users);
  const { loading: companiesLoading, companies } = useSelector(
    (state) => state.Companies
  );

  const [showCreateModal, setShowCreateModal] = useState(false);

  const [selectedUser, setSelectedUser] = useState();
  const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);

  const [companyIdSelected, setCompanyIdSelected] = useState("");
  const onSelectCompany = useCallback(
    (companyId) => {
      setCompanyIdSelected(companyId);

      if (companyId) {
        dispatch(getUsers({ companyId: companyId }));
      } else {
        // Get all company users
        dispatch(getUsers());
      }
    },
    [setCompanyIdSelected, dispatch]
  );

  const companiesOption = useMemo(() => {
    const options = [
      {
        value: "",
        label: TextConstants.Common.All,
      },
    ];

    if (companies && companies.length > 0) {
      return options.concat(
        companies.map((company) => {
          return {
            value: company.companyId,
            label: company.name,
          };
        })
      );
    }

    return options;
  }, [companies]);

  const mapCompanies = useMemo(() => {
    let data = {};
    companies.forEach((company) => {
      data[company.companyId] = company.name;
    });
    return data;
  }, [companies]);

  useEffect(() => {
    dispatch(getCompanies());
    dispatch(getUsers());
  }, [dispatch]);

  const handleOpenCreateModal = () => setShowCreateModal(true);

  const handleCreateUser = (values) => {
    if (values.companyId && values.companyId === "-") {
      delete values.companyId;
    }
    dispatch(
      createUser(values, () => {
        setShowCreateModal(false);
      })
    );
  };

  const handleCloseCreateModal = () => setShowCreateModal(false);

  const handleOpenChangePasswordModal = (user) => {
    setSelectedUser(user);
    setShowChangePasswordModal(true);
  };

  const handleChangePassword = (data) => {
    dispatch(
      changeUserPassword(
        {
          email: selectedUser.email,
          data,
        },
        handleCloseChangePasswordModal
      )
    );
  };

  const handleCloseChangePasswordModal = () => {
    setShowChangePasswordModal(false);
    setSelectedUser();
  };

  const reFetchUsers = useCallback(() => {
    if (companyIdSelected) {
      dispatch(getUsers({ companyId: companyIdSelected }));
    } else {
      // Get all company users
      dispatch(getUsers());
    }
  }, [dispatch, getUsers, companyIdSelected]);

  const handleDeleteUser = ({ email }) => {
    dispatch(deleteUser(email, reFetchUsers));
  };

  const usersDataMapped = useMemo(() => {
    return users.map((user) => {
      return {
        ...user,
      };
    });
  }, [users, mapCompanies]);

  const columns = [
    {
      title: TextConstants.Common.EmailAddress,
      dataIndex: "email",
      key: "email",
      sorter: (a, b) => a.email.localeCompare(b.email),
      width: "30%",
    },
    {
      title: TextConstants.Common.EmployeeName,
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
      width: "20%",
      defaultSortOrder: "ascend", // Set default sorting order
    },
    {
      title: TextConstants.Common.CompanyName,
      dataIndex: "companyName",
      key: "companyName",
      sorter: (a, b) => a.companyName.localeCompare(b.companyName),
      width: "30%",
    },
    {
      title: TextConstants.Common.Actions,
      dataIndex: "_actions",
      key: "_actions",
      canSearch: false,
      width: "20%",
      render: (_, record) => (
        <Row justify="center" gutter={[8, 6]}>
          <Col>
            <Button
              type="primary"
              size="small"
              onClick={() => handleOpenChangePasswordModal(record)}
            >
              {TextConstants.Common.Edit}
            </Button>
          </Col>
          <Col>
            <Popconfirm
              disabled={record.email === loggedInUser.email}
              title={TextConstants.Common.ConfirmDeleteMessage.replace(
                "{item}",
                TextConstants.Common.User
              )}
              onConfirm={() => handleDeleteUser(record)}
              okText={TextConstants.Common.Delete}
              cancelText={TextConstants.Common.Cancel}
              placement="topRight"
            >
              <Button
                size="small"
                disabled={record.email === loggedInUser.email}
                type="danger"
              >
                {TextConstants.Common.Delete}
              </Button>
            </Popconfirm>
          </Col>
        </Row>
      ),
    },
  ];

  return (
    <React.Fragment>
      {showCreateModal && (
        <CreateUserModal
          onClose={handleCloseCreateModal}
          onSubmit={handleCreateUser}
        />
      )}
      {showChangePasswordModal && (
        <ChangePasswordModal
          data={selectedUser}
          onClose={handleCloseChangePasswordModal}
          onSubmit={handleChangePassword}
        />
      )}
      <Row gutter={[24, 0]}>
        <Col xs={{ span: 24 }}>
          <Card
            className="tablespace mb-24"
            title={TextConstants.Pages.UserManagement}
          >
            <Divider
              orientation="left"
              plain
              style={{ marginBottom: 0, marginTop: 0 }}
            >
              <Typography.Title level={5}>
                {TextConstants.ROLES.FACILITY_ADMIN}
              </Typography.Title>
            </Divider>
            <BorderTable
              showSearch
              loading={loading}
              columns={columns}
              dataSource={usersDataMapped}
              rowKey={"email"}
              header={
                <Space>
                  {TextConstants.Common.CompanyName}
                  <Select
                    showSearch
                    placeholder={TextConstants.Common.CompanyName}
                    value={companyIdSelected}
                    loading={companiesLoading}
                    style={{
                      width: 180,
                    }}
                    onChange={onSelectCompany}
                    options={companiesOption}
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                  />
                </Space>
              }
            />
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default Users;
