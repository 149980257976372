import * as Types from "src/store/actions";

export const getFacilities = (params, callback = null) => ({
  type: Types.GET_FACILITIES.REQUEST,
  payload: params,
  callback,
});

export const createFacility = (params, callback = null) => ({
  type: Types.CREATE_FACILITY.REQUEST,
  payload: params,
  callback,
});

export const updateFacility = (params, callback = null) => ({
  type: Types.UPDATE_FACILITY.REQUEST,
  payload: params,
  callback,
});

export const deleteFacility = (params, callback = null) => ({
  type: Types.DELETE_FACILITY.REQUEST,
  payload: params,
  callback,
});

export const acceptFacilityInvitation = (params, callback = null) => ({
  type: Types.ACCEPT_FACILITY_INVITATION.REQUEST,
  payload: params,
  callback,
});
