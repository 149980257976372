import * as Types from "src/store/actions";

export const getUsers = (params, callback = null) => ({
  type: Types.GET_USERS.REQUEST,
  payload: params,
  callback,
});

export const createUser = (params, callback = null) => ({
  type: Types.CREATE_USER.REQUEST,
  payload: params,
  callback,
});

export const changeUserPassword = (params, callback = null) => ({
  type: Types.CHANGE_USER_PASSWORD.REQUEST,
  payload: params,
  callback,
});

export const deleteUser = (params, callback = null) => ({
  type: Types.DELETE_USER.REQUEST,
  payload: params,
  callback,
});

export const updateUserInfo = (params, callback = null) => ({
  type: Types.UPDATE_USER_INFO.REQUEST,
  payload: params,
  callback,
});

export const inviteFacilityUsers = (params, callback = null) => ({
  type: Types.INVITE_FACILITY_USERS.REQUEST,
  payload: params,
  callback,
});

export const getFacilityUsers = (params, callback = null) => ({
  type: Types.GET_FACILITY_USERS.REQUEST,
  payload: params,
  callback,
});

export const deleteFacilityUser = (params, callback = null) => ({
  type: Types.DELETE_FACILITY_USER.REQUEST,
  payload: params,
  callback,
});
