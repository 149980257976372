import { Select, Space } from "antd";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TextConstants from "src/constants/TextConstants";
import { getCompanies, getFacilities } from "src/store/actions";

const CompanyFacilitiesFilter = ({ onFacilitySelected = () => {} }) => {
  const dispatch = useDispatch();
  const { loading: companiesLoading, companies } = useSelector(
    (state) => state.Companies
  );
  const { loading: facilitiesLoading, facilities } = useSelector(
    (state) => state.Facilities
  );

  const [companyIdSelected, setCompanyIdSelected] = useState();
  const [facilityIdSelected, setFacilityIdSelected] = useState();

  useEffect(() => {
    dispatch(getCompanies());
  }, [dispatch]);

  useEffect(() => {
    if (companyIdSelected) {
      dispatch(getFacilities({ companyId: companyIdSelected }));
    }
  }, [companyIdSelected]);

  useEffect(() => {
    if (companies.length > 0) {
      setCompanyIdSelected(companies[0].companyId);
    }
  }, [companies, setCompanyIdSelected]);

  useEffect(() => {
    if (facilities.length > 0) {
      const firstFacilityId = facilities[0].facilityId;
      setFacilityIdSelected(firstFacilityId);
      onFacilitySelected(firstFacilityId);
    } else {
      setFacilityIdSelected();
      onFacilitySelected();
    }
  }, [facilities, setFacilityIdSelected, onFacilitySelected]);

  const companiesOption = useMemo(() => {
    return companies.map((company) => {
      return {
        value: company.companyId,
        label: company.name,
      };
    });
  }, [companies]);

  const facilitiesOption = useMemo(() => {
    return facilities.map((facility) => {
      return {
        value: facility.facilityId,
        label: facility.facilityName,
      };
    });
  }, [facilities]);

  const onSelectCompany = useCallback(
    (companyId) => {
      setCompanyIdSelected(companyId);
    },
    [setCompanyIdSelected]
  );

  const onSelectFacility = useCallback(
    (facilityId) => {
      setFacilityIdSelected(facilityId);
      onFacilitySelected(facilityId);
    },
    [setFacilityIdSelected, onFacilitySelected]
  );

  return (
    <>
      <Space size={"large"} wrap>
        <div>
          <Space>
            {TextConstants.Common.CompanyName}
            <Select
              showSearch
              placeholder={TextConstants.Common.CompanyName}
              value={companyIdSelected}
              loading={companiesLoading}
              style={{
                width: 180,
              }}
              onChange={onSelectCompany}
              options={companiesOption}
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
            />
          </Space>
        </div>
        <div>
          <Space>
            {TextConstants.NewFacility.FacilityName}
            <Select
              showSearch
              placeholder={TextConstants.NewFacility.FacilityName}
              value={facilityIdSelected}
              loading={facilitiesLoading || companiesLoading}
              style={{
                width: 180,
              }}
              onChange={onSelectFacility}
              options={facilitiesOption}
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
            />
          </Space>
        </div>
      </Space>
    </>
  );
};

export default CompanyFacilitiesFilter;
