import produce from "immer";
import {
  LOCAL_STORAGE_KEYS,
  NEW_DEPLOYMENT_STEPS_BY_MODE,
} from "src/constants";
import { getUserInfo } from "src/helpers/AuthHelper";
import * as Actions from "../actions";

const initState = {
  loading: false,
  isCreating: false,
  isUpdating: false,
  floors: [],
  facilityFloor: {},
  newDeployment: {}, // new floor
  floorFetchedAt: null, // Unix time
  floorSettingsFileURL: null, // https://s3.domain.file/filename.key...
  type: "",
};

const Floors = (state = initState, action) => {
  const { type, payload } = action;
  switch (type) {
    case Actions.UPDATE_NEW_DEPLOYMENT_LOCAL:
      return produce(state, (draft) => {
        const newDeploymentUpdated = { ...state.newDeployment, ...payload };
        draft.newDeployment = newDeploymentUpdated;
        draft.type = type;
        const user = getUserInfo();
        if (user && user.email) {
          localStorage.setItem(
            `${LOCAL_STORAGE_KEYS.NEW_DEPLOYMENT_SETTINGS}_${newDeploymentUpdated.facilityId}_${user.email}`,
            JSON.stringify(newDeploymentUpdated)
          );
        }
      });
    case Actions.GET_FACILITY_FLOOR.REQUEST:
    case Actions.GET_FLOORS.REQUEST:
    case Actions.DELETE_FLOOR.REQUEST:
    case Actions.GET_FLOOR_MAP_SETTINGS_FILE_URL.REQUEST:
      return produce(state, (draft) => {
        draft.loading = true;
        draft.type = type;
      });
    case Actions.GET_FLOORS.SUCCESS:
      return produce(state, (draft) => {
        draft.loading = false;
        draft.floors = payload;
        draft.floorFetchedAt = new Date().getTime();
        draft.type = type;
      });
    case Actions.GET_FACILITY_FLOOR.SUCCESS:
      return produce(state, (draft) => {
        draft.loading = false;
        draft.facilityFloor = payload;
        draft.type = type;
      });
    case Actions.GET_FACILITY_FLOOR.FAILURE:
    case Actions.GET_FLOORS.FAILURE:
    case Actions.DELETE_FLOOR.SUCCESS:
    case Actions.DELETE_FLOOR.FAILURE:
    case Actions.GET_FLOOR_MAP_SETTINGS_FILE_URL.FAILURE:
      return produce(state, (draft) => {
        draft.loading = false;
        draft.type = type;
      });
    case Actions.GET_FLOOR_MAP_SETTINGS_FILE_URL.SUCCESS:
      return produce(state, (draft) => {
        draft.floorSettingsFileURL = payload.url;
        draft.loading = false;
        draft.type = type;
      });
    case Actions.CREATE_FLOOR.REQUEST:
      return produce(state, (draft) => {
        draft.isCreating = true;
        draft.type = type;
      });
    case Actions.CREATE_FLOOR.SUCCESS:
      return produce(state, (draft) => {
        draft.isCreating = false;
        draft.type = type;
        draft.newDeployment = {};

        // TODO: refactor here
        const user = getUserInfo();
        if (user && user.email) {
          localStorage.removeItem(
            `${LOCAL_STORAGE_KEYS.NEW_DEPLOYMENT_SETTINGS}_${payload.facilityId}_${user.email}`
          );
        }
      });
    case Actions.CREATE_FLOOR.FAILURE:
      return produce(state, (draft) => {
        draft.isCreating = false;
        draft.type = type;

        // Set the step No back to one level if confirm and submit failed!
        const newDeploymentCurrent = state.newDeployment;
        let newStepNo = newDeploymentCurrent.stepNo;
        const settingSteps =
          NEW_DEPLOYMENT_STEPS_BY_MODE[newDeploymentCurrent.settingMode];
        if (settingSteps) {
          newStepNo = settingSteps.length - 2;
        }
        const newDeploymentUpdated = {
          ...newDeploymentCurrent,
          stepNo: newStepNo,
        };
        draft.newDeployment = newDeploymentUpdated;
      });
    case Actions.UPDATE_FLOOR.REQUEST:
      return produce(state, (draft) => {
        draft.isUpdating = true;
        draft.type = type;
      });
    case Actions.UPDATE_FLOOR.SUCCESS:
      return produce(state, (draft) => {
        draft.isUpdating = false;
        draft.floors = state.floors.map((floor) => {
          return floor.floorId === payload.floorId
            ? { ...floor, ...payload }
            : floor;
        });
        draft.type = type;
      });
    case Actions.UPDATE_FLOOR.FAILURE:
      return produce(state, (draft) => {
        draft.isUpdating = false;
        draft.type = type;
      });
    default:
      return state;
  }
};

export default Floors;
