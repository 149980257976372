import produce from "immer";
import * as Actions from "../actions";

const initState = {
  loading: false,
  acceptingInvitation: false,
  acceptedInvitation: false,
  facilities: [],
  fetchedAt: null,
  type: "",
};

const Facilities = (state = initState, action) => {
  const { type, payload } = action;
  switch (type) {
    case Actions.ACCEPT_FACILITY_INVITATION.REQUEST:
      return produce(state, (draft) => {
        draft.acceptingInvitation = true;
        draft.type = type;
      });

    case Actions.GET_FACILITIES.REQUEST:
    case Actions.CREATE_FACILITY.REQUEST:
    case Actions.UPDATE_FACILITY.REQUEST:
    case Actions.DELETE_FACILITY.REQUEST:
      return produce(state, (draft) => {
        draft.loading = true;
        draft.type = type;
      });

    case Actions.GET_FACILITIES.SUCCESS:
      return produce(state, (draft) => {
        draft.loading = false;
        draft.facilities = payload;
        draft.fetchedAt = new Date().getTime();
        draft.type = type;
      });

    case Actions.CREATE_FACILITY.SUCCESS:
      return produce(state, (draft) => {
        draft.loading = false;
        draft.facilities = [...state.facilities, { ...payload, isOwner: true }];
        draft.type = type;
      });

    case Actions.UPDATE_FACILITY.SUCCESS:
      return produce(state, (draft) => {
        draft.loading = false;
        draft.facilities = state.facilities.map((facility) => {
          if (facility.facilityId === payload.facilityId) {
            return { ...facility, ...payload };
          }
          return facility;
        });
      });

    case Actions.GET_FACILITIES.FAILURE:
    case Actions.CREATE_FACILITY.FAILURE:
    case Actions.UPDATE_FACILITY.FAILURE:
    case Actions.DELETE_FACILITY.FAILURE:
    case Actions.DELETE_FACILITY.SUCCESS:
      return produce(state, (draft) => {
        draft.loading = false;
        draft.type = type;
      });

    case Actions.ACCEPT_FACILITY_INVITATION.FAILURE:
      return produce(state, (draft) => {
        draft.acceptingInvitation = false;
        draft.type = type;
      });
    case Actions.ACCEPT_FACILITY_INVITATION.SUCCESS:
      return produce(state, (draft) => {
        draft.acceptingInvitation = false;
        draft.acceptedInvitation = true;
        draft.type = type;
      });

    default:
      return state;
  }
};

export default Facilities;
