import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import "./assets/styles/main.less";
import "./assets/styles/responsive.css";
import Main from "./containers/Main";
import "./custom-antd.less"; // Import custom .less file
import Login from "./pages/login/Login";
import Register from "./pages/register/Register";

import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ROLES } from "./constants";
import AcceptFacilityInvitation from "./pages/acceptFacilityInvitation";
import ConfirmEmailAddress from "./pages/confirmEmailAddress";
import PasswordResetRequest from "./pages/passwordResetRequest/PasswordResetRequest";
import ResetPassword from "./pages/resetPassword/ResetPassword";
import { PATHS, routes } from "./routes";
import { getAppReleaseVersion } from "./store/actions";

function App() {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.Auth);
  const isAuthenticated = useMemo(() => !!user, [user]);

  useEffect(() => {
    dispatch(getAppReleaseVersion());
  }, [dispatch]);

  return (
    <BrowserRouter>
      <Routes>
        <Route path={PATHS.LOGIN} element={<Login />} />
        <Route path={PATHS.SIGNUP} element={<Register />} />
        <Route
          path={PATHS.PASSWORD_RESET_REQUEST}
          element={<PasswordResetRequest />}
        />
        <Route path={PATHS.RESET_PASSWORD} element={<ResetPassword />} />
        <Route
          path={PATHS.ACCEPT_FACILITY_INVITATION}
          element={<AcceptFacilityInvitation />}
        />
        <Route
          path={PATHS.CONFIRM_EMAIL_ADDRESS_BY_TOKEN}
          element={<ConfirmEmailAddress />}
        />

        {routes.map((route, idx) => {
          return (
            route.component && (
              <Route
                key={idx}
                path={route.path}
                element={<Main children={<route.component />} />}
              />
            )
          );
        })}

        {!isAuthenticated && (
          <Route path="*" element={<Navigate to={PATHS.LOGIN} replace />} />
        )}

        {isAuthenticated &&
          user &&
          [ROLES.FA, ROLES.SU].includes(user.role) && (
            <Route
              path="*"
              element={<Navigate to={PATHS.DASHBOARD} replace />}
            />
          )}

        {isAuthenticated && user && user.role === ROLES.SA && (
          <Route
            path="*"
            element={<Navigate to={PATHS.ADMIN_USERS} replace />}
          />
        )}
      </Routes>
    </BrowserRouter>
  );
}

export default App;
