import { all, call, fork, put, takeEvery } from "redux-saga/effects";

import client from "src/helpers/HTTPClient";
import { Notification } from "src/helpers/Notification";
import TextConstants from "src/constants/TextConstants";
import * as Actions from "../actions";
import { createActionStates } from "../utils";
import { API_ENDPOINTS } from "src/constants";

function* getFacilityFloorSaga({ type, payload }) {
  const { onSuccess, onFailure } = createActionStates(type);
  try {
    const { facilityId, floorId } = payload;
    const response = yield call(
      client.get,
      API_ENDPOINTS.FACILITY_FLOOR.replace("{:facilityId}", facilityId).replace(
        "{:floorId}",
        floorId
      )
    );

    yield put(onSuccess(response));
  } catch (error) {
    yield put(onFailure(error));
  }
}

function* getFloorsSaga({ type, payload: facilityId }) {
  const { onSuccess, onFailure } = createActionStates(type);
  try {
    const response = yield call(
      client.get,
      API_ENDPOINTS.FLOORS.replace("{:facilityId}", facilityId)
    );

    yield put(onSuccess(response));
  } catch (error) {
    yield put(onFailure(error));
  }
}

function* createFloorSaga({ type, payload, callback }) {
  const { onSuccess, onFailure } = createActionStates(type);
  try {
    const facilityId = payload.facilityId;
    const response = yield call(
      client.post,
      API_ENDPOINTS.FLOORS.replace("{:facilityId}", facilityId),
      payload
    );

    yield put(onSuccess(response));
    callback && callback();
  } catch (error) {
    yield put(onFailure(error));
  }
}

function* updateFloorSaga({ type, payload, callback }) {
  const { onSuccess, onFailure } = createActionStates(type);
  try {
    const endpoint = `${API_ENDPOINTS.FACILITY_FLOOR.replace(
      "{:facilityId}",
      payload.facilityId
    ).replace("{:floorId}", payload.floorId)}`;
    const response = yield call(client.patch, endpoint, payload);

    yield put(onSuccess(response));
    callback && callback();
  } catch (error) {
    yield put(onFailure(error));
  }
}

function* deleteFloorSaga({ type, payload, callback }) {
  const { onSuccess, onFailure } = createActionStates(type);
  try {
    const { facilityId, floorId } = payload;
    const endpoint = `${API_ENDPOINTS.FACILITY_FLOOR.replace(
      "{:facilityId}",
      facilityId
    ).replace("{:floorId}", floorId)}`;
    yield call(client.delete, endpoint);

    yield put(onSuccess());
    Notification.success(TextConstants.DeleteFloorModal.SuccessMessage);

    callback && callback();
  } catch (error) {
    yield put(onFailure(error));
  }
}

function* getFloorMapSettingFileURL({ type, payload, callback }) {
  const { onSuccess, onFailure } = createActionStates(type);
  try {
    const response = yield call(
      client.get,
      `${
        API_ENDPOINTS.GET_FLOORS_SETTINGS_FILE_PRESIGNED_URL
      }?${new URLSearchParams(payload).toString()}`
    );

    yield put(onSuccess(response));

    callback && callback();
  } catch (error) {
    yield put(onFailure(error));
  }
}

export function* watchFloorsSagas() {
  yield takeEvery(Actions.GET_FLOORS.REQUEST, getFloorsSaga);
  yield takeEvery(Actions.GET_FACILITY_FLOOR.REQUEST, getFacilityFloorSaga);
  yield takeEvery(Actions.CREATE_FLOOR.REQUEST, createFloorSaga);
  yield takeEvery(Actions.UPDATE_FLOOR.REQUEST, updateFloorSaga);
  yield takeEvery(Actions.DELETE_FLOOR.REQUEST, deleteFloorSaga);
  yield takeEvery(
    Actions.GET_FLOOR_MAP_SETTINGS_FILE_URL.REQUEST,
    getFloorMapSettingFileURL
  );
}

export default function* rootSaga() {
  yield all([fork(watchFloorsSagas)]);
}
