import TextConstants from "./constants/TextConstants";

import AdminBMInformationScreen from "./pages/bm/BMInformation";
import AdminPowerDataScreen from "./pages/bm/PowerData";
import AdminSendBMCommandScreen from "./pages/bm/SendBMCommand";
import AdminFLoorMangementScreen from "./pages/floors/admin/Floors";
import UserManagementScreen from "./pages/users/Users";

import GUFLoorMangementScreen from "./pages/floors/generalUser/Floors";
import Facilities from "./pages/facilities/Facility";
import FacilityFloors from "./pages/facilities/FacilityFloors";
import NewFloorMapSettings from "./pages/facilityFloors/newFloor/NewFloorMapSettings";
import EditFloorMapSettings from "./pages/facilityFloors/editFloor/EditFloorMapSettings";
import NewDeploymentDashboardScreen from "./pages/newDeployment/dashboard/Dashboard";
import NewFloorMapSettingsScreen from "./pages/newDeployment/newFloorMapSettings/NewFloorMapSettings";
import SurveyScreen from "./pages/newDeployment/survey/Survey";

// Facility admin, setup user
import Dashboard from "./pages/dashboard/Dashboard";
import NewFacility from "./pages/facilities/NewFacility";
import Facility from "./pages/facilities/Facility";
import FacilityUserManagement from "./pages/users/FacilityUserManagement";

const PATHS = {
  // Admin
  ADMIN_DASHBOARD: "/admin/dashboard",
  ADMIN_USERS: "/admin/users",
  ADMIN_FLOOR_PLAN: "/admin/floors",
  ADMIN_BM_INFORMATION: "/admin/bm-information",
  ADMIN_POWER_DATA: "/admin/power-data",
  ADMIN_SEND_COMMAND: "/admin/send-command",

  // General user
  NEW_DEPLOYMENT: "/new-deployment",
  NEW_DEPLOYMENT_SURVEY: "/new-deployment/survey",
  NEW_DEPLOYMENT_FLOOR_MAP_SETTINGS: "/new-deployment/floor-map-settings",
  FLOOR_MANAGEMENT: "/floor-management",
  FLOOR_MANAGEMENT_DETAIL: "/floor-management/:floorId",

  // Facility admin
  NEW_FACILITY: "/new-facility",
  FACILITY_DETAIL: "/facility/:facilityId",
  NEW_FACILITY_FLOOR: "/facility/:facilityId/new-floor",
  EDIT_FACILITY_FLOOR: "/facility/:facilityId/floor/:floorId",
  FACILITY_USER_MANAGEMENT: "/facility/:facilityId/user-management",

  DASHBOARD: "/dashboard",
  // DASHBOARD: "/floors",
  // COMPANIES: "/companies",
  // OFFICES: "/offices",
  LOGIN: "/auth/login",
  SIGNUP: "/auth/signup",
  PASSWORD_RESET_REQUEST: "/auth/password-reset-request",
  RESET_PASSWORD: "/auth/reset-password",
  CONFIRM_EMAIL_ADDRESS_BY_TOKEN: "/auth/confirm-email-address",
  ACCEPT_FACILITY_INVITATION: "/accept-facility-invitation",
};

const routes = [
  // start: system admin - pages
  {
    path: PATHS.ADMIN_USERS,
    name: TextConstants.Pages.UserManagement,
    component: UserManagementScreen,
    exact: true,
  },
  {
    path: PATHS.ADMIN_FLOOR_PLAN,
    name: TextConstants.Pages.FloorPlan,
    component: AdminFLoorMangementScreen,
    exact: true,
  },
  {
    path: PATHS.ADMIN_BM_INFORMATION,
    name: TextConstants.Pages.BMInformation,
    component: AdminBMInformationScreen,
    exact: true,
  },
  {
    path: PATHS.ADMIN_POWER_DATA,
    name: TextConstants.Pages.PowerData,
    component: AdminPowerDataScreen,
    exact: true,
  },
  {
    path: PATHS.ADMIN_SEND_COMMAND,
    name: TextConstants.Pages.SendBMCommand,
    component: AdminSendBMCommandScreen,
    exact: true,
  },

  // start: facility
  {
    path: PATHS.DASHBOARD,
    name: TextConstants.Pages.Dashboard,
    component: Dashboard,
    exact: true,
  },
  {
    path: PATHS.NEW_FACILITY,
    name: TextConstants.Pages.AddNewFacility,
    component: NewFacility,
    exact: true,
  },
  {
    path: PATHS.FACILITY_DETAIL,
    name: "Facility detail",
    component: Facility,
    exact: true,
  },
  {
    path: PATHS.NEW_FACILITY_FLOOR,
    name: "",
    component: NewFloorMapSettings,
    exact: true,
  },
  {
    path: PATHS.EDIT_FACILITY_FLOOR,
    name: "",
    component: EditFloorMapSettings,
    exact: true,
  },
  {
    path: PATHS.FACILITY_USER_MANAGEMENT,
    name: "",
    component: FacilityUserManagement,
    exact: true,
  },

  // start: general user - pages
  {
    path: PATHS.NEW_DEPLOYMENT,
    name: TextConstants.Pages.RegisteredNewPropertyChecking,
    component: NewDeploymentDashboardScreen,
    exact: true,
  },
  {
    path: PATHS.NEW_DEPLOYMENT_SURVEY,
    name: "new deployment survey",
    component: SurveyScreen,
    exact: true,
  },
  {
    path: PATHS.NEW_DEPLOYMENT_FLOOR_MAP_SETTINGS,
    name: "new deployment floor map settings",
    component: NewFloorMapSettingsScreen,
    exact: true,
  },
  {
    path: PATHS.FLOOR_MANAGEMENT,
    name: "floor management by general user",
    component: GUFLoorMangementScreen,
    exact: true,
  },
  {
    path: PATHS.FLOOR_MANAGEMENT_DETAIL,
    name: "floor management by general user",
    component: GUFLoorMangementScreen,
    exact: true,
  },
  {
    path: "/facility-management",
    name: "",
    component: Facilities,
    exact: true,
  },
  // {
  //   path: "/facility-management/new",
  //   name: "",
  //   component: NewFacility,
  //   exact: true,
  // },
  {
    path: "/facility-management/floors",
    name: "",
    component: FacilityFloors,
    exact: true,
  },
];

export { PATHS, routes };
