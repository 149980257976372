import { Card, Col, Row } from "antd";
import dayjs from "dayjs";
import React, { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import BorderTable from "src/components/BorderTable";
import CompanyFacilitiesFilter from "src/components/CompanyFacilitiesFilter/CompanyFacilitiesFilter";
import FloorStatus from "src/components/FloorStatus";
import {
  FLOOR_SETTINGS_MODE_LABEL,
  SENSOR_OPERATION_METHOD_LABEL,
} from "src/constants";
import TextConstants from "src/constants/TextConstants";
import { getFloors } from "src/store/actions";

const columns = [
  {
    title: TextConstants.Common.Name,
    dataIndex: "name",
    key: "name",
    sorter: (a, b) => a.name.localeCompare(b.name),
    width: "20%",
    defaultSortOrder: "ascend", // Set default sorting order
  },
  {
    title: TextConstants.Common.Mode,
    dataIndex: "settingModeName",
    key: "settingModeName",
    sorter: (a, b) => a.settingModeName.localeCompare(b.settingModeName),
    width: "15%",
  },
  {
    title: TextConstants.Common.OperationMethod,
    dataIndex: "operationMethodName",
    key: "operationMethodName",
    width: "15%",
  },
  {
    title: TextConstants.Common.Status,
    dataIndex: "statusLabel",
    key: "statusLabel",
    width: "15%",
    sorter: (a, b) => a.statusLabel.localeCompare(b.statusLabel),
    render: (_, record) => <FloorStatus registered={record.registered} />,
  },
  {
    title: TextConstants.Common.BMFWVersion,
    dataIndex: "bmFWVersion",
    key: "bmFWVersion",
    width: "10%",
  },
  {
    title: TextConstants.Common.LightFWVersion,
    dataIndex: "lightFWVersion",
    key: "lightFWVersion",
    width: "10%",
  },
  {
    title: TextConstants.Common.CreatedAt,
    dataIndex: "createdAt",
    key: "createdAt",
    width: "15%",
    sorter: (a, b) => a.createdAt.localeCompare(b.createdAt),
  },
];

const Floors = () => {
  const dispatch = useDispatch();

  const { floors, loading: isLoadingFloorData } = useSelector(
    (state) => state.Floors
  );

  const floorsUpdated = useMemo(() => {
    return floors.map((floor) => {
      return {
        ...floor,
        lightFWVersion: floor.lightFWVersion || "-",
        bmFWVersion: floor.bmFWVersion || "-",
        settingModeName: FLOOR_SETTINGS_MODE_LABEL[floor.settingMode],
        operationMethodName:
          SENSOR_OPERATION_METHOD_LABEL[floor.operationMethod] || "--",
        createdAt: dayjs(floor.createdAt).format("YYYY/MM/DD HH:mm:ss"),
        statusLabel: floor.registered
          ? TextConstants.FacilityFloors.FloorStatus.AlreadySetup
          : TextConstants.FacilityFloors.FloorStatus.WaitingForSettings,
      };
    });
  }, [floors]);

  const onFacilitySelected = useCallback(
    (facilityId) => {
      dispatch(getFloors(facilityId));
    },
    [dispatch, getFloors]
  );

  return (
    <React.Fragment>
      <Row gutter={[24, 0]}>
        <Col xl={{ span: 24 }}>
          <Card
            bordered={true}
            className="criclebox tablespace mb-24"
            title={TextConstants.Pages.FloorPlan}
          >
            <BorderTable
              rowKey={"floorId"}
              showSearch
              loading={isLoadingFloorData}
              columns={columns}
              dataSource={floorsUpdated}
              header={
                <CompanyFacilitiesFilter
                  onFacilitySelected={onFacilitySelected}
                />
              }
            />
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default Floors;
